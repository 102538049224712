"use client"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import * as React from "react"

import { cn } from "@/app/_utils"
import { AlertTriangle, Info } from "lucide-react"

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md border border-elementalGray bg-white px-3 py-1.5 text-sm text-gray-950 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

/**
 * A hover over with helpful information.
 * @param text The text to display
 * @param className Additional Tailwind classes for styling
 * @param size Tailwind sizes only, see: https://tailwindcss.com/docs/width; defaults to 4
 * @returns
 */
function InfoTip({
  text,
  className,
  size,
}: {
  readonly text: string
  readonly className?: string
  readonly size?: number
}) {
  return (
    <Tooltip>
      <TooltipTrigger type="button">
        <Info
          className={cn(
            `mr-1 size-3 text-gray-400 dark:text-gray-300`,
            className
          )}
        />
      </TooltipTrigger>
      <TooltipContent sideOffset={5}>{text}</TooltipContent>
    </Tooltip>
  )
}

function AlertTip({
  text,
  className,
}: {
  readonly text: string
  readonly className?: string
}) {
  return (
    <Tooltip>
      <TooltipTrigger type="button">
        <AlertTriangle
          className={cn("mx-1 size-4 text-black dark:text-white", className)}
        />
      </TooltipTrigger>
      <TooltipContent sideOffset={5}>{text}</TooltipContent>
    </Tooltip>
  )
}

export {
  AlertTip,
  InfoTip,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
}
