"use client"
import type { LucideIcon } from "lucide-react"
import { AtSign, Code, FileQuestion, Ruler, SearchCheck } from "lucide-react"
import { useRouter } from "next/navigation"

const ResourceButton = ({
  icon: Icon,
  label,
  url,
}: {
  readonly icon: LucideIcon
  readonly label: string
  readonly url: string
}) => {
  const router = useRouter()
  return (
    <button
      className="flex cursor-pointer items-center space-x-2 rounded bg-box-background p-4 hover:shadow-lg max-sm:my-2 max-sm:w-full xl:w-[400px] dark:bg-box-background-dark"
      onClick={() => router.push(url)}
    >
      <Icon className="h-6" />
      <div className="ml-4 text-left font-semibold">{label}</div>
    </button>
  )
}

export function Resources() {
  const resources = [
    {
      icon: Code,
      label: "PARS CPP JSON Schema Documentation",
      url: "https://json.pars.doe.gov",
    },
    {
      icon: SearchCheck,
      label: "PARS Data Quality Wiki",
      url: "https://wiki.pars.doe.gov",
    },
    {
      icon: FileQuestion,
      label: "PARS FAQs",
      url: "https://support.pars.doe.gov/support/solutions/folders/70000379689",
    },
    {
      icon: AtSign,
      label: "Request a PARS Account",
      url: "https://support.pars.doe.gov/support/tickets/new",
    },
    {
      icon: Ruler,
      label: "Login to IP2M METRR",
      url: "https://ip2m.pars.doe.gov",
    },
  ]

  return (
    <div className="mt-5 flex-col space-y-4">
      <h2 className="mt-6 text-2xl font-semibold">PARS Resources</h2>
      <div className="grid-cols-2 gap-4 sm:grid">
        {resources.map((resource, index) => (
          <ResourceButton
            key={index + resource.label}
            icon={resource.icon}
            label={resource.label}
            url={resource.url}
          />
        ))}
      </div>
    </div>
  )
}
