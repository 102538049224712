"use client"

import { Button } from "@/app/components/ui/button"
import { Input } from "@/app/components/ui/input"
import { Label } from "@radix-ui/react-label"
import { Loader2 } from "lucide-react"
import { useState } from "react"
import { OneIDButton } from "./OneIDButton"
import signInToAzure from "./signInToAzure"

export function PARSLoginBox({
  username_hint,
}: {
  readonly username_hint?: string
}) {
  let [username, setUsername] = useState(username_hint ?? "")
  let [isLoading, setIsLoading] = useState(false)
  return (
    <div className="w-full space-y-4 rounded-lg bg-white p-6 shadow-lg dark:bg-gray-800">
      <h2 className="text-3xl font-bold">Sign in to PARS</h2>
      <div className="w-full space-y-2">
        <Label htmlFor="username">Username or Email</Label>
        <Input
          id="username"
          defaultValue={username}
          required
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>

      <Button
        className="w-full"
        onClick={() => {
          if (username.length > 0) signInToAzure(setIsLoading, username)
        }}
        disabled={isLoading}
      >
        {isLoading ? <Loader2 className="w-6 animate-spin" /> : "Sign In"}
      </Button>

      <div className="mt-5 font-bold">
        New! Use your DOE OneID for login, including PIV login
      </div>
      <OneIDButton />
    </div>
  )
}
