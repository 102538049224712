"use client" // Error components must be Client components

import { ErrorDisplay } from "@/app/(tailwind)/Projects/[parsid]/datasheet/_components/ErrorDisplay"
import * as Sentry from "@sentry/nextjs"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
import useSWR from "swr"

const fetcher = (...args: Parameters<typeof fetch>) =>
  fetch(...args).then((res) => res.json())

export function ErrorPageComponent({
  error,
  reset,
}: {
  readonly error: Error
  readonly reset: () => void
}) {
  const router = useRouter()

  const { data, isLoading } = useSWR("/api/user", fetcher, {
    fallbackData: null,
  })

  // Render Sentry's Crash Report dialog, which collects information from the user about the issues.
  // (Except during development mode, where we show as much detail as the stack trace allows.)

  useEffect(() => {
    if (
      window.location.hostname &&
      window.location.hostname !== "localhost" &&
      data &&
      !isLoading
    ) {
      Sentry.setUser({
        id: data.user?.email,
        email: data.user?.email,
        name: data.user?.name,
      })
      Sentry.showReportDialog({
        user: {
          name: data.user?.email,
          email: data.user?.email,
        },
        onClose: () => {
          router.push("/")
        },
      })
    }
  }, [error, reset, router, data, isLoading])

  if (window.location.hostname === "localhost") {
    return <ErrorDisplay error={error} resetErrorBoundary={reset} />
  }

  return null
}
