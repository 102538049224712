/* eslint-disable @next/next/no-img-element */
"use client"

export function NewLoginBanner() {
  return (
    <div className="mt-5 flex max-w-[800px] flex-row rounded-lg border-2 border-black bg-white p-4 duration-1000 animate-in slide-in-from-left dark:bg-gray-800">
      <img
        src="stop.png"
        className="size-40"
        alt="A large red stop sign to draw users attention to the announcement"
      />
      <div>
        <p className="mt-2 text-xl font-semibold text-gray-700 dark:text-gray-300">
          {`PARS has a new authentication system. For now, this is optional but will become the required authentication method in the future. To start using it, please click on the "Login with OneID" button on the right.`}
        </p>
        <p className="mt-2 text-xl font-semibold text-gray-700 dark:text-gray-300">
          {`This will allow login with your DOE PIV Card or DOE Lab or Site Account.`}
        </p>
        <p className="mt-2 text-xl font-semibold text-gray-700 dark:text-gray-300">
          {`If you have any issues logging in with the new system, please reach out to PARS Support.`}
        </p>
      </div>
    </div>
  )
}
