"use client"
import { signIn } from "next-auth/react"
export function OneIDButton() {
  return (
    <button
      type="button"
      className="inline-flex items-center rounded-lg border-2 border-gray-400 bg-white px-4 py-2 text-gray-800 hover:bg-gray-100"
      onClick={() => signIn("oneid")}
    >
      <span className="mr-2">Login with DOE OneID</span>
    </button>
  )
}
