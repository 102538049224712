"use client" // Error components must be Client components

import { Alert, AlertDescription, AlertTitle } from "@/app/components/ui/alert"
import { AlertCircle } from "lucide-react"

import { Button } from "@/app/components/ui/button"
import ErrorStackParser from "error-stack-parser"

export function ErrorDisplay({
  error,
  resetErrorBoundary,
}: {
  readonly error: Error
  readonly resetErrorBoundary: () => void
}) {
  let environment: string

  switch (window.location.hostname) {
    case "localhost":
      environment = "dev"
      break
    case "pars.doe.gov":
      environment = "prod"
      break
    case "test.pars.doe.gov":
    case "doepars.com":
      environment = "test"
      break
    case "preview.pars.doe.gov":
      environment = "preview"
      break
    default:
      environment = "unknown"
  }
  if (environment == "dev") {
    console.error(error)
    let errorStack = ErrorStackParser.parse(error)
    return (
      <div className="flex size-full flex-row justify-center">
        <Alert className="m-5 size-fit max-w-6xl rounded bg-white outline outline-red-600 dark:bg-gray-700">
          <AlertCircle className="size-6" />
          <AlertTitle className="mb-4 text-xl">{`${error.name} (Development Server)`}</AlertTitle>
          <AlertDescription className="flex flex-col">
            <div className="h-fit max-h-[600px] min-h-[150px] overflow-y-auto border bg-white p-3 font-mono dark:bg-gray-800 dark:text-white">
              {error.message}
            </div>
            <div className="mt-2 h-fit max-h-[600px] min-h-[150px] overflow-y-auto border bg-white p-3 font-mono dark:bg-gray-800 dark:text-white">
              <div>{`Error occurred in function: ${errorStack[0].functionName}()`}</div>
              <div>{`Error occurred in file: ${errorStack[0].fileName?.split(
                "rsc/./"
              )[errorStack[0].fileName?.split("rsc/./").length - 1]} on line ${
                errorStack[0].lineNumber
              }`}</div>
              <div className="mt-3">
                {" "}
                Click on the red Error button in the bottom left for more
                details.
              </div>
            </div>
            <a
              className="text-xs text-blue-800 underline"
              href="https://sentry.pars.doe.gov/organizations/pars/issues/?environment=development&project=2&statsPeriod=1h"
            >
              {`You may also be able to find some useful information about your error in Sentry, our monitoring tool.`}
            </a>
            <div className="flex flex-row">
              <Button
                className="m-3 h-10 w-80"
                onClick={() => {
                  // Refresh the page
                  try {
                    window.location.reload()
                  } catch (e) {
                    console.error("Unable to refresh page: " + e)
                  }
                }}
              >
                Fixed it? Click here to force a reload
              </Button>
            </div>
          </AlertDescription>
        </Alert>
      </div>
    )
  }

  return (
    <div className="flex size-full flex-row justify-center">
      <Alert className="m-5 size-fit max-w-6xl rounded bg-white outline dark:bg-gray-700 ">
        <AlertCircle className="size-6" />
        <AlertTitle className="mb-4 pb-1 text-xl">
          Error displaying this content
        </AlertTitle>
        <AlertDescription className="flex flex-col">
          <div className="mb-2 max-w-2xl">
            Unexpected data or unexpected permissions configuration prevented
            this page from displaying. If this happens more than once, please
            reach out to PARS Support and let them know what you were trying to
            do.
          </div>
          <div className="flex flex-row">
            <Button
              className="m-3 h-10 w-40"
              onClick={() => {
                // Refresh the page
                try {
                  window.location.reload()
                } catch (e) {
                  console.error("Unable to refresh page: " + e)
                }
              }}
            >
              Try again
            </Button>
            <Button asChild className="m-3 w-40 bg-green-800 text-white">
              <a href="https://support.pars.doe.gov">Contact Support</a>
            </Button>{" "}
          </div>
        </AlertDescription>
      </Alert>
    </div>
  )
}
