import * as React from "react"

import { cn } from "@/app/_utils"
import { InputNumberFormat } from "@react-input/number-format"
import { format } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import {
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { UseFormReturn } from "react-hook-form"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./form"

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, hidden, ...props }, ref) => (
    <input
      type={type}
      hidden={hidden}
      pattern={props.pattern}
      className={cn(
        "flex h-10 w-full rounded-md border border-elementalGray bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800  dark:bg-form-background-dark dark:text-white dark:ring-offset-gray-950 dark:placeholder:text-black dark:focus-visible:ring-gray-300",
        className
      )}
      ref={ref}
      {...props}
    />
  )
)
Input.displayName = "Input"

// A debounced input react component
function DebouncedInput({
  className,
  type,
  value: initialValue,
  hidden,
  onChange,
  debounce = 300,
  ...props
}: {
  readonly value: string | number
  readonly onChange: (value: string | number) => void
  readonly debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, onChange, debounce])

  return (
    <input
      type={type}
      hidden={hidden}
      onChange={(e) => setValue(e.target.value)}
      pattern={props.pattern}
      className={cn(
        "flex h-10 w-full rounded-md border border-elementalGray bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800  dark:bg-form-background-dark dark:text-white dark:ring-offset-gray-950 dark:placeholder:text-black dark:focus-visible:ring-gray-300",
        className
      )}
      {...props}
    />
  )
}

function FormInput({
  className,
  form,
  field,
  placeholder,
  label,
  ...props
}: InputProps & { readonly field: string; readonly label?: string } & {
  readonly form: UseFormReturn<any, any>
}) {
  return (
    <FormField
      control={form.control}
      name={field}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <>
              {label ? <FormLabel>{label}</FormLabel> : null}
              <Input
                {...field}
                value={field.value ?? ""}
                className={cn(
                  "my-0 flex h-8 w-[90px] rounded-md border border-elementalGray bg-white  px-3 py-2 text-xs  ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
                  className
                )}
                placeholder={placeholder}
                disabled={props.disabled}
                type={props.type}
                readOnly={props.readOnly}
              />
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

function cleanNumericInputValue(value: string) {
  if (value == "") {
    return null
  }
  // value = value.replace(",", "")
  if (isNaN(parseFloat(value))) {
    return value
  }
  return parseFloat(value)
}

interface FormNumberInputProps {
  readonly className?: string
  readonly field: string
  readonly form: UseFormReturn<any>
  readonly disabled?: boolean
  readonly maxLength?: number
  readonly type?: string
}
function FormNumberInput({
  className,
  form,
  field,
  disabled = false,
  type = "number",
  maxLength,
  ...props
}: FormNumberInputProps) {
  const [inputValue, setInputValue] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    []
  )

  useEffect(() => {
    const formValue = form.getValues(field)

    if (formValue === null || formValue === undefined || formValue === "") {
      setInputValue("")
    } else {
      const formattedValue = formatter.format(formValue)
      setInputValue(formattedValue)
    }
  }, [form, field, formatter])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
    const selectionStart = e.target.selectionStart || 0

    // Remove all non-numeric characters except for the decimal point
    const numericValue = rawValue.replace(/[^0-9.]/g, "")

    // Split the number on decimal to limit decimal places
    const parts = numericValue.split(".")
    if (parts.length > 2) {
      // Invalid number (more than one decimal point), ignore input
      return
    }

    let integerPart = parts[0]
    let decimalPart = parts[1] || ""

    // Limit decimal part to two digits
    if (decimalPart.length > (type === "number" ? 2 : 0)) {
      decimalPart = type === "number" ? decimalPart.substring(0, 2) : ""
    }

    // Reconstruct the number
    let newValue = integerPart
    if (numericValue.includes(".")) {
      newValue += "." + decimalPart
    }

    // Parse the number
    const numberValue =
      newValue.trim() === "" || newValue === "." ? null : parseFloat(newValue)

    // Update form value
    form.setValue(field, numberValue)

    // Format the integer part with commas
    const formattedInteger = integerPart
      ? formatter.format(parseInt(integerPart, 10))
      : ""

    let formattedValue = formattedInteger

    // Append decimal point and decimal part if present
    if (numericValue.includes(".")) {
      formattedValue += "."
      if (decimalPart.length > 0) {
        formattedValue += decimalPart
      }
    }

    // Update the input value state
    setInputValue(formattedValue)

    // Update the cursor position
    setTimeout(() => {
      if (inputRef.current) {
        const newCursorPosition = calculateNewCursorPosition(
          rawValue,
          formattedValue,
          selectionStart
        )
        inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    }, 0)
  }

  // Helper function to calculate the new cursor position
  const calculateNewCursorPosition = (
    rawValue: string,
    formattedValue: string,
    cursorPosition: number
  ) => {
    // Calculate the difference in length between the raw and formatted values
    const lengthDifference = formattedValue.length - rawValue.length

    // Adjust the cursor position
    return cursorPosition + lengthDifference
  }

  const handleInputBlur = () => {
    const formValue = form.getValues(field)
    if (formValue === null || formValue === undefined || formValue === "") {
      setInputValue("")
    } else {
      const formattedValue = formatter.format(formValue)
      setInputValue(formattedValue)
    }
  }

  return (
    <FormField
      name={field}
      render={({ field: formField }) => (
        <FormItem>
          <FormControl>
            <input
              {...props}
              ref={inputRef}
              type="text"
              inputMode="decimal"
              className={cn(
                "my-0 flex h-8 w-[90px] rounded-md border border-elementalGray bg-white px-3 py-2 text-xs ring-offset-white placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                className
              )}
              value={inputValue}
              disabled={disabled}
              maxLength={maxLength}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
function cleanDateInputValue(value: string) {
  if (value == "") {
    return null
  }

  return value
}

function FormDateInput({
  className,
  form,
  field,
  ...props
}: InputProps & { readonly field: string } & {
  readonly form: UseFormReturn<any, any>
}) {
  return (
    <FormField
      control={form.control}
      name={field}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <Input
              {...field}
              value={(() => {
                if (!field.value) {
                  return ""
                }
                return format(toZonedTime(field.value, "UTC"), "yyyy-MM-dd")
              })()}
              placeholder="mm/dd/yyyy"
              onBlur={(e) => {
                field.onChange(cleanDateInputValue(e.target.value))
              }}
              type="date"
              className={cn(
                "h-8 w-[120px] text-xs placeholder:-ml-2 placeholder:tracking-tight",
                className
              )}
              disabled={props.disabled}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export { DebouncedInput, FormDateInput, FormInput, FormNumberInput, Input }
